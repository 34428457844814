import React from 'react';

import contactImage from 'assets/images/pages/contact/1.jpg';

import Title from 'blocks/Title';
import Content from 'blocks/Content';
import ContactForm from 'blocks/ContactForm';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import Heading from 'components/Heading';
import Text from 'components/Text';
import Link from 'components/Link';
import Icon from 'components/Icon';

import config from '../config';

const OfferteAanvragen = () => (
  <Layout>
    <Seo image={{ src: contactImage }} />

    <Title
      image={{ src: contactImage }}
      alt="Vrijblijvende offerte aanvragen"
      subtitle="Bedankt voor je interesse"
      title="Vrijblijvende offerte aanvragen? Bedankt voor je interesse!"
    >
      Vul onderstaand formulier in of neem contact met ons op. Wij zullen een
      volledig vrijblijvende offerte opmaken die past bij jouw merk en
      doelstellingen!
    </Title>

    <Section>
      <Content wide align="left">
        <ContactForm title="Bel ons, mail ons of vraag hieronder direct een offerte aan">
          <Heading color="white">Adres gegevens</Heading>

          {config.company.address.map((row) => (
            <Text color="white">{row}</Text>
          ))}

          <Link as="a" color="white" href={config.company.mapsLink}>
            <Icon name="location" /> Route plannen
          </Link>

          <br />

          <Heading color="white">Contact gegevens</Heading>

          <Link as="a" color="white" href={`tel:${config.company.phone}`}>
            <Icon name="phone" /> {config.company.phone}
          </Link>
          <Link as="a" color="white" href={`mailto:${config.company.email}`}>
            <Icon name="mail" /> {config.company.email}
          </Link>
        </ContactForm>
      </Content>
    </Section>
  </Layout>
);

export default OfferteAanvragen;
